

export const userCustomLayer = { pins: [], polylines: [], polygons: [] }

export const distanceCircle = {
    // 距离圈图像缩放倍数
    scaleFactor: 4,
    // 圆圈弧线
    // 以固定距离画线
    circleFixedDist: false,
    // 弧线间隔KM
    circleIntervalDist: 10,
    // 圈数
    circleCount: 5,
    // 角度分割线
    // 显示分割线
    lineVisible: true,
    // 角度间隔
    lineAngle: 30,
    // 显示最内圈
    lineInnerVisible: false,
    // 线上距离数字显示
    lineMark: true,
    // 线宽px
    lineWeight: 2,
    // 线颜色
    lineColor: "#000000",
    // 探测范围
    // 显示探测范围
    shadingVisible: true,
    // 探测范围填充颜色
    shadingColor: "#a8a8a81a"
}

export const system = {
    // 本雷达的网络编号
    siteNetworkId: "ZBAD",
    // 启动时，默认启用产品自动更新（已设置）
    autoUpdateDefaultOn: false,
    // 自动更新时长（已设置）
    autoUpdateInterval: 5.5,
    // 启动时，默认启用产品动态显示（已设置）
    dynamicDisplayDefaultOn: false,
    // 动态显示时长（已设置）
    dynamicDisplayInterval: 3,
    // 动态显示时，产品序列的长度
    dynamicDisplayLoopLength: 60,
    // 气象产品显示页中，时间轴的长度
    timeBarLength: 60,
    // 基数据
    // 在本地进行基数据的保存
    dataStorge_Base_SavingOn: true,
    // 保存时长
    dataStorge_Base_SavingDay: 10,
    // 基数据保存路径
    dataStorge_Base_SavingPath: "/data/base",
    // 预处理后数据
    // 是否对预处理后的数据进行自动清理
    dataStorge_Preprocess_AutoCleanOn: true,
    // 保存时长
    dataStorge_Preprocess_SavingDay: 10,
    // 预处理后数据保存路径
    dataStorge_Preprocess_SavingPath: "/data/preprocess",
    // 产品数据
    // 是否对产品数据进行自动清理
    dataStorge_Product_AutoCleanOn: true,
    // 保存时长
    dataStorge_Product_SavingDay: 10,
    // 产品数据保存路径
    dataStorge_Product_SavingPath: "/data/product",
}

export const geoInfo = {
    cityName: {
        fontFamily: "黑体",
        fontSize: 16.0,
        fontColor: "#000000"
    },
    districtName: {
        fontFamily: "黑体",
        fontSize: 16.0,
        fontColor: "#000000"
    },
    countryLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    provinceLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    cityLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    districtLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    roadLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    lakePolygon: {
        outlineWidth: 2.0,
        outlineColor: "#00FFBB",
        fillColor: "#06ACFF"
    }
}

export const opts_fontFamily = [
    {
        "value": "黑体",
        "label": "黑体"
    },
    {
        "value": "Microsoft YaHei UI",
        "label": "微软雅黑"
    },
    {
        "value": "楷体",
        "label": "楷体"
    }
]

export const networkParam = {
    // 后端地址
    backendAddress: "http://127.0.0.1:8000",
    wmsTilingAddress: "http://127.0.0.1:4000/geoserver/china/wms",
    terrainTilingAddress: "/terrain"
}

export const weatherAlarm = {
    //进行弱回波滤除时的强度阈值门限
    weakEchoFilterThreshold: 15,
    //进行强回波预警时的强度阈值门限
    strongEchoForewarningThreshold: 40,
    //进行强回波预警时强回波的闪烁频率
    strongEchoForewarningFrequency: 1,
    //在气象产品显示页面的预警栏中，对这些危险天气进行预警
    forewarningType: ['1', '2', '3', '4', '5', '6', '7', '8'],
    //在气象产品显示页面的回波图上，叠加显示以下危险天气
    superpositionShowType: ['1', '2', '3', '4', '5', '6', '7', '8'],
    opts_weatherIcon: [
        {
            value: "1",
            label: "风暴结构",
            pic: [{ url: "/common/weather/风暴.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "2",
            label: "下击暴流",
            pic: [{ url: "/common/weather/下击暴流.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "3",
            label: "冰雹指数",
            pic: [{ url: "/common/weather/冰雹.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "4",
            label: "风暴追踪",
            pic: [{ url: "/common/weather/风暴.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "5",
            label: "阵风锋",
            pic: [{ url: "/common/weather/阵风锋.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "6",
            label: "龙卷漩涡",
            pic: [{ url: "/common/weather/龙卷涡旋.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "7",
            label: "暴雨",
            pic: [{ url: "/common/weather/暴雨.png" }],
            barShow: true,
            echoShow: true
        },
        {
            value: "8",
            label: "中尺度气旋",
            pic: [{ url: "/common/weather/中尺度气旋.png" }],
            barShow: true,
            echoShow: true
        },
    ]
}

export const enum_id2Weather = {
    "1": "stormStructure",//风暴结构
    "2": "downburst",//下击暴流
    "3": "hailIndex",//冰雹指数
    "4": "stormTracking",//风暴追踪
    "5": "gustFront",//阵风锋
    "6": "tornadoVortex",//龙卷漩涡
    "7": "rainstorm",//暴雨
    "8": "mesoscaleCyclone"//中尺度气旋
}

export const enum_weather2Id = {
    stormStructure: "1",
    downburst: "2",
    hailIndex: "3",
    stormTracking: "4",
    gustFront: "5",
    tornadoVortex: "6",
    rainstorm: "7",
    mesoscaleCyclone: "8"
}