// 前后端json转换
import * as dftCfg from './dftCfg'

export const uploadImgUrl = "/file/uploadFile"
export const fetchImgUrl = "/file/getImg"

// 获取单站产品列表，params下字符串->{label: param, productUrl: param}
export const fetch_listProduct = ((data) => {
    data.forEach(productTypeGroupObj => {
        productTypeGroupObj.children.multi.forEach(productTypeObj => {
            let ppiParams = []
            productTypeObj.children.ppi.params.forEach(param => {
                ppiParams.push({ label: param.param, productUrl: JSON.stringify(param) })
            });
            productTypeObj.children.ppi.params = ppiParams

            let cappiParams = []
            productTypeObj.children.cappi.params.forEach(param => {
                cappiParams.push({ label: param.param, productUrl: JSON.stringify(param) })
            });
            productTypeObj.children.cappi.params = cappiParams
        });
        productTypeGroupObj.children.single.forEach(productTypeObj => {
            let params = []
            productTypeObj.params.forEach(param => {
                params.push({ label: param.param, productUrl: JSON.stringify(param) })
            })
            productTypeObj.params = params
        })
    });
    console.log(data)
    return data
})

// 距离圈参数
export const fetch_distanceCircle = ((data) => {
    data.circleFixedDist = data.circleFixedDist == "T" ? true : false
    data.lineVisible = data.lineVisible == "T" ? true : false
    data.lineInnerVisible = data.lineInnerVisible == "T" ? true : false
    data.lineMark = data.lineMark == "T" ? true : false
    data.shadingVisible = data.shadingVisible == "T" ? true : false
    return data
})

export const send_distanceCircle = ((newForm) => {
    newForm.circleFixedDist = newForm.circleFixedDist ? "T" : "F"
    newForm.lineVisible = newForm.lineVisible ? "T" : "F"
    newForm.lineInnerVisible = newForm.lineInnerVisible ? "T" : "F"
    newForm.lineMark = newForm.lineMark ? "T" : "F"
    newForm.shadingVisible = newForm.shadingVisible ? "T" : "F"
    return newForm
})

//系统参数
export const fetch_systemParam = ((data) => {
    data.autoUpdateDefaultOn = data.autoUpdateDefaultOn == "T" ? true : false
    data.dynamicDisplayDefaultOn = data.dynamicDisplayDefaultOn == "T" ? true : false
    return data
})

export const send_systemParam = ((newForm) => {
    newForm.autoUpdateDefaultOn = newForm.autoUpdateDefaultOn ? "T" : "F"
    newForm.dynamicDisplayDefaultOn = newForm.dynamicDisplayDefaultOn ? "T" : "F"
    return newForm
})

// 危险天气
export const fetch_weatherAlarm = ((data) => {
    // 读取网络参数
    let storageKey = "param_network"
    const networkParam = JSON.parse(localStorage.getItem(storageKey)) || JSON.parse(JSON.stringify(dftCfg.networkParam))
    data = { ...dftCfg.weatherAlarm, ...data }

    data.forewarningType = data.forewarningType.split(",")
    data.superpositionShowType = data.superpositionShowType.split(",")
    // 修改opts_weatherIcon
    data.opts_weatherIcon.forEach(weather => {
        weather.barShow = data.forewarningType.includes(weather.value) ? true : false
        weather.echoShow = data.superpositionShowType.includes(weather.value) ? true : false
    });

    let keys = Object.keys(dftCfg.enum_weather2Id)
    keys.forEach(key => {
        const remotePicUrl = networkParam.backendAddress + fetchImgUrl + "?imgName=" + data[key]
        const id = dftCfg.enum_weather2Id[key]
        data.opts_weatherIcon.forEach(weather => {
            // 重新指定url
            if (weather.value === id) {
                weather.pic = [{ url: remotePicUrl }]
            }
        });
    });

    return data
})

export const send_weatherAlarm = ((newForm) => {
    newForm.forewarningType = newForm.forewarningType.join(",")
    newForm.superpositionShowType = newForm.superpositionShowType.join(",")
    return newForm
})

// 健康状态
export const fetch_healthStatus = ((data) => {
    data.servoStatus = data.servoStatus == "t" ? true : false
    data.powerStatus = data.powerStatus == "t" ? true : false
    data.oilengineStatus = data.oilengineStatus == "t" ? true : false
    data.arraypowerStatus = data.arraypowerStatus == "t" ? true : false
    data.shelterStatus = data.shelterStatus == "t" ? true : false
    data.bdStatus = data.bdStatus == "t" ? true : false
    data.bdfStatus = data.bdfStatus == "t" ? true : false
    data.sisnalStatus = data.sisnalStatus == "t" ? true : false
    data.sersorStatus = data.sersorStatus == "t" ? true : false
    data.monitorStatus = data.monitorStatus == "t" ? true : false
    return data
})